import type {FooterProps} from '@/components/footer';
import type {SiteSettingsFragment} from '@/sanity/lib/queries';
import {resolveLinkHref} from '@/shared';

export const footerPropsFromSiteSettingsFragment = (
	siteSettings: SiteSettingsFragment,
): FooterProps => {
	const {organization, contactInfo, footerLinks} = siteSettings;

	return {
		organization: {
			organizationNumber: organization?.number,
			address: contactInfo?.address,
			secondaryAddress: contactInfo?.secondaryAddress,
			phoneNumber: contactInfo?.phoneNumber,
			email: contactInfo?.email,
			name: organization?.name,
		},
		links: footerLinks?.map((link) => ({
			_key: link._key,
			href: resolveLinkHref(link),
			title: link.title,
			targetBlank: link.targetBlank,
		})),
	};
};
