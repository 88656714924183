import type {FC} from 'react';
import {usePathname} from 'next/navigation';

import {cn} from '@/shared';

import {Button} from '../button';
import {Link} from '../link';

export type NavLinkType = {
	type: 'navlink';
	title: string;
	href: string;
	targetBlank?: boolean;
	featured?: boolean;
};

type NavLinkProps = {
	link: NavLinkType;
	onClick?: () => void;
	className?: string;
};

export const NavLink: FC<NavLinkProps> = ({link, onClick, className}) => {
	const pathname = usePathname();

	const isActive = pathname === link.href;

	if (link.featured) {
		return (
			<Button asChild size="medium">
				<Link
					onClick={onClick}
					href={link.href}
					targetBlank={link.targetBlank}
					className={cn('inline-block', 'max-lg:text-4xl', className)}
				>
					{link.title}
				</Link>
			</Button>
		);
	}

	return (
		<Link
			onClick={onClick}
			href={link.href}
			targetBlank={link.targetBlank}
			className={cn(
				'relative',
				'inline-block',
				'no-underline',
				'hover:text-secondary',
				'transition-all',
				'max-lg:text-4xl',
				'text-white',
				isActive
					? [
							'text-secondary',
							'after:absolute',
							'after:h-[3px]',
							'after:w-full',
							'after:bg-secondary',
							'after:bottom-0',
							'max-lg:after:-bottom-2',
							'after:left-0',
							'after:right-0',
							'after:rounded-full',
						]
					: [
							'after:absolute',
							'after:h-[3px]',
							'after:w-full',
							'after:bg-secondary',
							'after:-bottom-3',
							'after:opacity-0',
							'after:left-0',
							'after:right-0',
							'after:rounded-full',
							'after:duration-100',
							'after:origin-bottom',
							'hover:after:bottom-0',
							'max-lg:hover:after:-bottom-2',
							'hover:after:origin-right',
							'hover:after:opacity-100',
						],
				className,
			)}
		>
			{link.title}
		</Link>
	);
};
