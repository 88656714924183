import {type HTMLAttributes} from 'react';

import {cn} from '@/shared';

export const containerBackgroundColors = [
	{
		title: 'Ingen',
		value: 'transparent',
	},
	{
		title: 'Hvit',
		value: 'white',
	},
	{
		title: 'Primær',
		value: 'primary',
	},
] as const;

export const containerBackgroundColor = containerBackgroundColors.map((color) => color.value);

export type ContainerSpacing = 'none' | 'small' | 'normal' | 'large';
export type ContainerBackgroundColor = (typeof containerBackgroundColor)[number];

export type ContainerProps = HTMLAttributes<HTMLElement> & {
	as?: 'div' | 'header' | 'section' | 'article' | 'aside' | 'footer' | 'ul';
	width?: 'text' | 'normal' | 'full';
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	hasBackground?: boolean;
	backgroundColor?: ContainerBackgroundColor;
};

/**
 * @name Container
 * @description
 * * Renders a 3 column grid container.
 * * Children span the middle column.
 * * To make  a direct child like an image span to the edge of the screen,
 * set the className of the child to "!col-span-full".
 *
 * To use this component, the `tailwind.config.js` file must be updated to include the following:
 * ```js
 * {
 *   theme: {
 *     extend: {
 *       gridTemplateColumns: {
 *         'container-text': 'minmax(24px, 1fr) minmax(auto, 768px) minmax(24px, 1fr)',
 *         'container-normal': 'minmax(24px, 1fr) minmax(auto, 1280px) minmax(24px, 1fr)',
 *         'container-full': 'minmax(24px, 1fr) minmax(auto, 100%) minmax(24px, 1fr)',
 *       },
 *     },
 *   },
 * }
 * ```
 *
 * @param {string} [as] - The HTML tag to render the container as, defaults to 'div'.
 * @param {string} [width] - The width of the container. Can be 'text', 'normal', or 'full'.
 * @param {string} [spacingTop] - The top margin of the container. Can be 'none', 'small', 'normal', or 'large'.
 * @param {string} [spacingBottom] - The bottom margin of the container. Can be 'none', 'small', 'normal', or 'large'.
 * @param {boolean} [hasBackground] - Whether the container has a background.
 * @param {string} [backgroundColor] - The background color of the container. Can be 'transparent', 'white', 'primary', or 'secondary'.
 * @param {ReactNode} children - The children to render inside the container.
 * @param {string} [className] - The class name to apply to the container.
 * @param {HTMLAttributes<HTMLElement>} [rest] - The rest of the props to apply to the container.
 * @returns {React.ReactElement} The container component.
 */
export const Container = (props: ContainerProps) => {
	const {
		as,
		children,
		width = 'normal',
		className,
		spacingBottom,
		spacingTop,
		hasBackground,
		backgroundColor = 'transparent',
		...rest
	} = props;

	const Component = as ?? 'div';

	let spacingBottomString = '';
	let spacingTopString = '';

	const containerHasBackground = hasBackground || backgroundColor !== 'transparent';

	switch (spacingBottom) {
		case 'none':
			spacingBottomString = containerHasBackground ? 'pb-0' : 'mb-0';
			break;
		case 'small':
			spacingBottomString = containerHasBackground ? 'pb-4' : 'mb-4';
			break;
		case 'normal':
			spacingBottomString = containerHasBackground ? 'pb-12' : 'mb-12';
			break;
		case 'large':
			spacingBottomString = containerHasBackground ? 'pb-24' : 'mb-24';
			break;
	}

	switch (spacingTop) {
		case 'none':
			spacingTopString = containerHasBackground ? 'pt-0' : 'mt-0';
			break;
		case 'small':
			spacingTopString = containerHasBackground ? 'pt-4' : 'mt-4';
			break;
		case 'normal':
			spacingTopString = containerHasBackground ? 'pt-12' : 'mt-12';
			break;
		case 'large':
			spacingTopString = containerHasBackground ? 'pt-24' : 'mt-24';
			break;
	}

	let gridTemplateColumns = '';

	switch (width) {
		case 'text':
			gridTemplateColumns = 'grid-cols-container-text';
			break;
		case 'normal':
			gridTemplateColumns = 'grid-cols-container-normal';
			break;
		case 'full':
			gridTemplateColumns = 'grid-cols-container-full';
			break;
		default:
			gridTemplateColumns = 'grid-cols-container-normal';
	}

	let bgColor = 'transparent';

	switch (backgroundColor) {
		case 'primary':
			bgColor = 'bg-[#F6F4FF]';
			break;
		case 'white':
			bgColor = 'bg-white';
			break;
		case 'transparent':
			bgColor = 'bg-transparent';
			break;
	}

	return (
		<Component
			className={cn([
				'relative',
				'grid',
				'items-center',
				gridTemplateColumns,
				'[&>*]:col-start-2',
				'[&>*]:col-end-2',
				spacingBottomString,
				spacingTopString,
				bgColor,
				className,
			])}
			{...rest}
		>
			{children}
		</Component>
	);
};

Container.displayName = 'Container';
