import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';

import {cn} from '@/shared';

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant = 'primary' | 'secondary';

const buttonStyles = cva(
	cn(
		'no-underline',
		'inline-flex',
		'items-center',
		'justify-center',
		'gap-2',
		'shrink-0',
		'rounded-full',
		'text-base',
		'transition-colors',
		'border',
		'text-center',
		'leading-tight',
	),
	{
		variants: {
			intent: {
				primary: [
					'bg-primary-medium',
					'text-white',
					'border-transparent',
					'hover:bg-[#7F72EE]',
				],
				secondary: [
					'bg-white',
					'text-primary-medium',
					'border-primary-medium',
					'hover:bg-[#E6DFFF]',
				],
			},
			size: {
				small: ['px-4', 'py-1'],
				medium: ['px-[30px]', 'py-[10px]'],
				large: ['px-10', 'py-4'],
			},
		},
		compoundVariants: [{intent: 'primary', size: 'medium'}],
		defaultVariants: {
			intent: 'primary',
			size: 'medium',
		},
	},
);

export type ButtonProps = VariantProps<typeof buttonStyles> &
	ComponentPropsWithoutRef<'button'> & {
		/**
		 * Change the default rendered element for the one passed as a child, merging their props and behavior.
		 * @default false
		 */
		asChild?: boolean;
	};

/**
 * Button used for interaction
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			children,
			intent = 'primary',
			size = 'medium',
			type = 'button',
			className,
			asChild,
			...rest
		},
		ref,
	) => {
		const Component = asChild ? Slot : 'button';

		return (
			<Component
				ref={ref}
				type={type}
				className={cn(buttonStyles({intent, size}), className)}
				{...rest}
			>
				{children}
			</Component>
		);
	},
);

Button.displayName = 'Button';
